import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { sbIcons } from '@sb/svg-icons';

import { IconButtonComponent } from '../button';
import { IconComponent } from '../icon';

/** @deprecated direct use the variant input instead of enum and color input */
export enum NoticeThemePalette {
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
}

export type noticeVariant = keyof typeof NoticeThemePalette;

export type noticeSize = 'sm' | 'md';

@Component({
  selector: 'sb-notice',
  standalone: true,
  imports: [CommonModule, IconComponent, IconButtonComponent],
  templateUrl: './notice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'sbNotice',
})
export class NoticeComponent {
  public icon: sbIcons = 'info-circle';

  private readonly baseClass = 'flex rounded border-2 border-solid p-3';

  private readonly variantClasses: Record<noticeVariant, string> = {
    info: 'text-grey-500 border-grey-300 bg-grey-100',
    warning: 'text-warning-500 border-warning-200 bg-warning-100',
    danger: 'text-error-500 border-error-200 bg-error-100',
    success: 'text-success-500 border-success-200 bg-success-50',
  };

  private readonly iconMap: Record<noticeVariant, sbIcons> = {
    info: 'info-circle',
    warning: 'exclamation-triangle',
    danger: 'exclamation-circle',
    success: 'check-circle',
  };

  private readonly sizeClasses: Record<noticeSize, string> = {
    sm: 'gap-2 text-sm',
    md: 'gap-3 text-base',
  };

  @HostBinding('role')
  readonly hostRole = 'alert';

  // used by host binding
  @HostBinding('class')
  private hostClasses = this.buildHostClasses('info', 'md');

  @Input()
  public title?: string | SafeHtml;
  @Input()
  public description!: string | SafeHtml;
  @Input()
  public link?: string;
  @Output()
  public linkClicked = new EventEmitter();

  @Input()
  set size(value: noticeSize) {
    if (this.size !== value) {
      this._size = value;
      this.hostClasses = this.buildHostClasses(this._variant, this.size);
    }
  }
  get size(): noticeSize {
    return this._size || 'md';
  }
  private _size: noticeSize = 'md';

  /**
   * @deprecated Use `variant` instead.
   */
  @Input()
  set color(value: noticeVariant) {
    this.variant = value;
  }

  @Input()
  set variant(value: noticeVariant) {
    if (this._variant !== value) {
      this._variant = value;
      this.icon = this.iconMap[this._variant];
      this.hostClasses = this.buildHostClasses(this._variant, this._size);
    }
  }

  private _variant: noticeVariant = 'info';

  private buildHostClasses(variant: noticeVariant, size: noticeSize): string {
    return `${this.baseClass} ${this.variantClasses[variant || 'info']} ${this.sizeClasses[size || 'md']}`;
  }
}
