import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { ConnectedPosition, Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions, Platform } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { auditTime, fromEvent, merge, takeUntil } from 'rxjs';

import { PopoverPlacement, popoverPlacement, PopoverPositions } from './popover.model';

const passiveListenerOptions = normalizePassiveListenerOptions({ passive: true });

export const MouseMoveAuditTime = 300;

@Directive({
  selector: '[sbPopover]',
  standalone: true,
  exportAs: 'sbPopover',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.aria-haspopup]': 'popoverTemplate ? "dialog" : null',
    '[attr.aria-expanded]': 'popoverTemplate == null ? null : isOpen()',
  },
})
export class PopoverDirective implements OnDestroy, AfterViewInit {
  @Input('sbPopover')
  popoverTemplate!: TemplateRef<unknown>;

  @Input('sbPopoverPosition')
  set position(value: popoverPlacement) {
    if (this._position !== value) {
      this._position = value;
      this.close();
    }
  }
  get position(): popoverPlacement {
    return this._position;
  }
  private _position: popoverPlacement = PopoverPlacement.bottom;

  @Input('sbPopoverDisabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // If trigger is disabled, hide immediately.
    if (this._disabled) {
      this.close();
    } else {
      this.setupTriggerListenersIfNeeded();
    }
  }
  private _disabled = false;

  // triggers cannot be dynamically set, the values are set once the component is initialized
  @Input('sbPopoverTrigger')
  get trigger(): 'click' | 'hover' {
    return this._trigger;
  }
  set trigger(value: 'click' | 'hover') {
    this._trigger = value;
  }
  private _trigger: 'click' | 'hover' = 'click';

  @Input('sbPopoverDelay')
  public delay = 0;

  @Output('sbPopoverOpened')
  readonly opened = new EventEmitter<MouseEvent>();
  @Output('sbPopoverClosed')
  readonly closed = new EventEmitter<MouseEvent>();

  private readonly destroyed$: EventEmitter<void> = new EventEmitter();
  private readonly closed$: EventEmitter<void> = new EventEmitter();
  private readonly stopListeners$ = merge(this.destroyed$, this.closed$);

  private _overlayRef!: OverlayRef;
  private _portal: TemplatePortal<unknown> | null = null;
  private _showTimeoutId?: ReturnType<typeof setTimeout>;

  private readonly _passiveListeners: (readonly [string, EventListenerOrEventListenerObject])[] = [];

  constructor(
    private _elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private _scrollDispatcher: ScrollDispatcher,
    private _platform: Platform,
  ) {}

  ngAfterViewInit(): void {
    this.setupTriggerListenersIfNeeded();
  }

  ngOnDestroy() {
    this.cancelDelayedOpen();
    this.removeTriggerListenersIfNeeded();
    this.detachOverlay();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  close() {
    this.cancelDelayedOpen();

    // Emit on closed$ to make sure all subscriptions relaying on it are completed
    this.closed$.next();

    if (!this.isOpen()) {
      return;
    }

    this.detachOverlay();
    this.closed.emit();
  }

  open(delay: number = this.delay) {
    if (this.isOpen() || this.disabled) {
      return;
    }

    this.cancelDelayedOpen();

    if (this.trigger === 'hover') {
      // This is added before attaching the overlay (aka opening the popover) because that action might be delayed
      // and the user could move out of the "elementRef" before the popover is actually opened
      void fromEvent(document, 'mousemove')
        .pipe(auditTime(MouseMoveAuditTime), takeUntil(this.stopListeners$))
        .subscribe((event: Event) => {
          if (this.isMovedOutside(event)) {
            this.close();
          }
        });
    }

    this._showTimeoutId = setTimeout(() => {
      this.createOverlay();
      this.attachOverlay();

      this.opened.emit();

      this._showTimeoutId = undefined;
    }, delay);
  }

  isOpen(): boolean {
    return !!this._overlayRef?.hasAttached();
  }

  private cancelDelayedOpen() {
    if (this._showTimeoutId !== undefined) {
      clearTimeout(this._showTimeoutId);
      this._showTimeoutId = undefined;
    }
  }

  private createOverlay(): void {
    if (this._overlayRef) {
      this.detachOverlay();
    }

    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);
    const { [this.position]: _, ...rest } = PopoverPositions;

    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._elementRef)
      .withFlexibleDimensions(false)
      .withScrollableContainers(scrollableAncestors)
      .withPositions([PopoverPositions[this.position], ...Object.values<ConnectedPosition>(rest)]);

    this._overlayRef = this._overlay.create({
      hasBackdrop: this.trigger === 'click' ? true : false,
      backdropClass: '',
      positionStrategy: strategy,
      scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 20 }),
    });

    this._overlayRef
      .backdropClick()
      .pipe(takeUntil(this.stopListeners$))
      .subscribe(() => {
        this.detachOverlay();
      });

    this._overlayRef
      .detachments()
      .pipe(takeUntil(this.stopListeners$))
      .subscribe(() => {
        this.detachOverlay();
      });

    // close on escape
    this._overlayRef
      .keydownEvents()
      .pipe(takeUntil(this.stopListeners$))
      .subscribe((event) => {
        if (event.keyCode === ESCAPE && !hasModifierKey(event)) {
          this.close();
          event.preventDefault();
          event.stopPropagation();
        }
      });
  }

  private attachOverlay(): void {
    if (this._overlayRef?.hasAttached()) {
      return;
    }

    this._portal = this._portal || new TemplatePortal(this.popoverTemplate, this.viewContainerRef);
    this._overlayRef.attach(this._portal);
  }

  private detachOverlay() {
    if (this._overlayRef?.hasAttached()) {
      this._overlayRef.detach();
    }
  }

  private setupTriggerListenersIfNeeded() {
    if (this.disabled || !this.popoverTemplate || this._passiveListeners.length) {
      return;
    }
    // more triggers can be added here if needed,
    // some triggers will need exit triggers as well
    const supportsMouseEvents = this.platformSupportsMouseEvents();
    const enterListener = () => {
      this.open();
    };
    const exitListener = () => {
      this.close();
    };
    if (this.trigger === 'click') {
      this._passiveListeners.push(['click', enterListener]);
    }
    if (this.trigger === 'hover') {
      if (supportsMouseEvents) {
        this._passiveListeners.push(['mouseenter', enterListener]);
      } else {
        this._passiveListeners.push(['touchstart', enterListener]);
        this._passiveListeners.push(['touchend', exitListener]);
        this._passiveListeners.push(['touchcancel', exitListener]);
      }
    }

    const nativeElement: HTMLElement = this._elementRef.nativeElement;
    this._passiveListeners.forEach(([event, listener]) => {
      nativeElement.addEventListener(event, listener, passiveListenerOptions);
    });
  }

  private platformSupportsMouseEvents() {
    return !this._platform.IOS && !this._platform.ANDROID;
  }

  private removeTriggerListenersIfNeeded() {
    const nativeElement = this._elementRef.nativeElement;
    this._passiveListeners.forEach(([event, listener]) => {
      nativeElement.removeEventListener(event, listener, passiveListenerOptions);
    });
    this._passiveListeners.length = 0;
  }

  private isMovedOutside(event: Event): boolean {
    const target = event.target as HTMLElement;
    return !(this._overlayRef?.overlayElement.contains(target) || this._elementRef.nativeElement.contains(target));
  }
}
