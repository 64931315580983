@if (inline) {
  <ng-container *ngTemplateOutlet="menu" />
} @else if (!customToggle) {
  <button
    [sbDropdownToggle]="menu"
    (sbDropdownToggleOpened)="onOpened()"
    sbInput
    [disabled]="disabled"
    [hasError]="hasError"
    class="w-full"
    type="button"
    [ngClass]="{
      'flex items-center gap-1': showPill && multiSelect && selectedCount,
      'border-primary-500 ': (showPill && multiSelect && selectedCount) || buttonActive
    }"
  >
    <span class="block h-5 truncate">{{ selectButtonLabel }}</span>
    @if (showPill && multiSelect && selectedCount) {
      <span
        class="flex size-3.5 items-center justify-center rounded-full bg-primary-500 text-xs leading-none text-white"
      >
        {{ selectedCount }}
      </span>
    }
  </button>
} @else {
  <ng-content />
}

<!-- TODO we should be able to do with just a single default item template
The TODOs below should be settled with UX -->
<ng-template #defaultItemTemplate let-item="item">
  <span class="truncate">{{ item.text }}</span>
</ng-template>
<!-- TODO why is there a discrepancy in the style for nodes between single/multi select? -->
<ng-template #defaultSingleSelectTreeItemTemplate let-item="item" let-level="level">
  <span
    class="truncate"
    [ngClass]="{
      'font-extrabold': level === 0,
      'font-semibold': level > 0
    }"
  >
    {{ item.text }}
  </span>
</ng-template>
<!-- TODO why can't multiselect items be truncated? -->
<ng-template #defaultMultiSelectItemTemplate let-item="item">
  {{ item.text }}
</ng-template>

<ng-template #onlyButton let-item="item">
  <button
    class="invisible m-0 cursor-pointer border-0 bg-transparent font-semibold text-primary-500 outline-none hover:underline group-hover:visible"
    (click)="onSelectOnly(item)"
  >
    {{ 'Only' | translate }}
  </button>
</ng-template>

<ng-template #menu>
  <div
    sbDropdownMenu
    [sbDropdownMenuInline]="inline"
    [ngStyle]="{ width: customToggle || !matchWidth ? undefined : dropdownWidth }"
    [ngClass]="{
      'w-full': inline,
      'min-w-44': !inline,
      'max-w-sm': customToggle
    }"
  >
    @if (searchable) {
      <div class="mx-4 mb-2.5 mt-1">
        <sb-search-input
          [placeholder]="searchPlaceholder || ('Search' | translate)"
          [autoFocusInput]="true"
          [(ngModel)]="query"
          (ngModelChange)="onSearch()"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    }
    @if (multiSelect && showSelectAll) {
      <div sbDropdownItemCheckbox>
        <sb-checkbox (change)="onSelectAll($event.checked)" [checked]="allChecked">
          {{ 'Select all' | translate }}
        </sb-checkbox>
      </div>
    }
    @if (filters.length > 0) {
      @for (filter of filters; track trackByFn) {
        <div sbDropdownItemCheckbox>
          <sb-checkbox (change)="onFilterChange($event.checked, filter)">
            {{ filter.label }}
          </sb-checkbox>
        </div>
      }
    }
    @if ((multiSelect && showSelectAll) || filters.length > 0) {
      <sb-dropdown-divider />
    }
    <div [ngClass]="{ 'max-h-72 overflow-y-auto overflow-x-hidden': !inline }">
      @if (!displayedItems.length) {
        <div sbDropdownBaseItem [hasHoverEffect]="false">
          <span>{{ 'No items found' | translate }}</span>
        </div>
      }
      @if (displayTree) {
        <sb-tree [items]="displayedItems" [collapsable]="collapsable">
          <ng-template #leafTemplate let-item="item" let-level="level">
            @if (multiSelect) {
              <div
                sbTreeNode
                [nodePadding]="nodePadding"
                [level]="level"
                sbDropdownItemCheckbox
                [ngClass]="{ group: showOnly && !item.disabled }"
              >
                @if (collapsable) {
                  <sb-select-collapse-spacer />
                }
                <sb-checkbox
                  [checked]="item.checked"
                  [disabled]="item.disabled"
                  (change)="onCheckboxChange($event.checked, item)"
                >
                  <ng-container
                    *ngTemplateOutlet="itemTemplateInput || defaultMultiSelectItemTemplate; context: { item, level }"
                  />
                </sb-checkbox>
                @if (showOnly && !item.disabled) {
                  <ng-container *ngTemplateOutlet="onlyButton; context: { item }"></ng-container>
                }
              </div>
            } @else {
              <button
                sbTreeNode
                [nodePadding]="nodePadding"
                [level]="level"
                sbDropdownItem
                [selected]="item.value === selected"
                (sbDropdownItemTriggered)="onItemTrigger(item)"
                [sbDropdownItemDisabled]="item.disabled"
              >
                @if (collapsable) {
                  <sb-select-collapse-spacer />
                }
                <ng-container *ngTemplateOutlet="itemTemplateInput || defaultItemTemplate; context: { item, level }" />
              </button>
            }
          </ng-template>
          <ng-template #nodeTemplate let-item="item" let-level="level">
            <div
              sbDropdownBaseItem
              tabindex="0"
              class="cursor-default"
              sbTreeNode
              [nodePadding]="nodePadding"
              [level]="level"
              [ngClass]="{ group: showOnly && !item.disabled }"
            >
              @if (collapsable) {
                <div
                  role="button"
                  [attr.aria-label]="'Toggle' | translate"
                  (click)="onToggleCollapse(item)"
                  class="mr-2.5 flex cursor-pointer items-center justify-center"
                >
                  <sb-icon [name]="'chevron-down'" [ngClass]="{ '-rotate-90': item.collapsed }"></sb-icon>
                </div>
              }
              @if (multiSelect) {
                <sb-checkbox
                  class="grow overflow-hidden"
                  [checked]="item.checked"
                  [indeterminate]="item.indeterminate"
                  [disabled]="item.disabled"
                  (change)="onCheckboxChange($event.checked, item)"
                >
                  <ng-container
                    *ngTemplateOutlet="itemTemplateInput || defaultItemTemplate; context: { item, level }"
                  />
                </sb-checkbox>
              }
              @if (multiSelect && showOnly && !item.disabled) {
                <ng-container *ngTemplateOutlet="onlyButton; context: { item }"></ng-container>
              }
              @if (!multiSelect) {
                <ng-container
                  *ngTemplateOutlet="itemTemplateInput || defaultSingleSelectTreeItemTemplate; context: { item, level }"
                />
              }
            </div>
          </ng-template>
        </sb-tree>
      } @else {
        @for (item of displayedItems; track trackByFn) {
          @if (multiSelect) {
            <div sbDropdownItemCheckbox [ngClass]="{ group: showOnly && !item.disabled }">
              @if (collapsable) {
                <sb-select-collapse-spacer />
              }
              <sb-checkbox
                [checked]="item.checked"
                [disabled]="item.disabled"
                (change)="onCheckboxChange($event.checked, item)"
              >
                <ng-container
                  *ngTemplateOutlet="itemTemplateInput || defaultMultiSelectItemTemplate; context: { item }"
                />
              </sb-checkbox>
              @if (showOnly && !item.disabled) {
                <ng-container *ngTemplateOutlet="onlyButton; context: { item }"></ng-container>
              }
            </div>
          } @else {
            <button
              sbDropdownItem
              [selected]="item.value === selected"
              (sbDropdownItemTriggered)="onItemTrigger(item)"
              [sbDropdownItemDisabled]="item.disabled"
            >
              @if (collapsable) {
                <sb-select-collapse-spacer class="mr-2.5 inline-block w-3.5 shrink-0" />
              }
              <ng-container *ngTemplateOutlet="itemTemplateInput || defaultItemTemplate; context: { item }" />
            </button>
          }
        }
      }
    </div>
  </div>
</ng-template>
