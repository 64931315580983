import { DOCUMENT } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  HostBinding,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { sbIcons } from '@sb/svg-icons';
import { clsx } from 'clsx';

import { IconRegistry } from './icon-registry.service';

export enum IconSize {
  'xxs' = 'xxs',
  'xs' = 'xs',
  'sm' = 'sm',
  'base' = 'base',
  'lg' = 'lg',
  'xl' = 'xl',
  '2xl' = '2xl',
}

export type iconSize = keyof typeof IconSize | undefined;

@Component({
  selector: 'sb-icon',
  standalone: true,
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  private readonly sizeClasses = {
    [IconSize.xxs]: clsx('h-2 w-2'), // 8px
    [IconSize.xs]: clsx('h-2.5 w-2.5'), // 10px
    [IconSize.sm]: clsx('h-3 w-3'), // 12px
    [IconSize.base]: clsx('h-3.5 w-3.5'), // 14px
    [IconSize.lg]: clsx('h-4 w-4'), // 16px
    [IconSize.xl]: clsx('h-5 w-5'), // 20px
    [IconSize['2xl']]: clsx('h-8 w-8'), // 32px
  };

  private svgIcon?: SVGElement;

  @Input()
  @HostBinding('attr.aria-label')
  get name(): sbIcons {
    return this._name;
  }
  set name(value: sbIcons) {
    if (value === this.name) {
      return;
    }
    this.clearSvgElement();
    if (value) {
      this.updateSvgIcon(value);
    }
    this._name = value;
  }
  private _name!: sbIcons;

  @Input()
  set size(value: iconSize) {
    if (value === this.size) {
      return;
    }
    this._size = value;
    this.classes = this.hostClasses;
  }
  get size(): iconSize {
    return this._size;
  }
  _size?: iconSize = IconSize.base;

  @HostBinding('attr.role')
  readonly role = 'img';

  @HostBinding('class')
  classes = this.hostClasses;

  constructor(
    private elementRef: ElementRef,
    private iconRegistry: IconRegistry,
    private readonly errorHandler: ErrorHandler,
    @Optional() @Inject(DOCUMENT) private document: any,
    @Attribute('aria-hidden') ariaHidden: string,
  ) {
    if (!ariaHidden) {
      elementRef.nativeElement.setAttribute('aria-hidden', 'true');
    }
  }

  private get hostClasses(): string {
    // eslint-disable-next-line tailwindcss/no-arbitrary-value, tailwindcss/no-custom-classname
    return clsx(['notranslate inline-block fill-current [&>svg]:block'], this.size ? this.sizeClasses[this.size] : '');
  }

  private updateSvgIcon(svgIcon: sbIcons): void {
    const svgData = this.iconRegistry.getIcon(svgIcon);
    if (!svgData) {
      this.errorHandler.handleError(new Error(`Cannot find icon with name ${svgIcon}`));
      return;
    }
    this.svgIcon = this.svgElementFromString(svgData);
    this.elementRef.nativeElement.appendChild(this.svgIcon);
    this.setSvgAttributes(this.svgIcon);
  }

  private clearSvgElement(): void {
    if (!this.svgIcon) {
      return;
    }
    this.elementRef.nativeElement.removeChild(this.svgIcon);
  }

  private svgElementFromString(svgData: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgData;
    return div.querySelector('svg');
  }

  private setSvgAttributes(svg: SVGElement): SVGElement {
    svg.setAttribute('fit', '');
    svg.setAttribute('height', '100%');
    svg.setAttribute('width', '100%');
    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    svg.setAttribute('focusable', 'false');
    return svg;
  }
}
