import { FocusKeyManager, FocusOrigin } from '@angular/cdk/a11y';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ScrollableDirective } from '../scrollable/scrollable.directive';
import { TabLabelDirective } from './tab-label.directive';
import { TabComponent } from './tab.component';

@Component({
  standalone: true,
  selector: 'sb-tab-group',
  exportAs: 'sbTabGroup',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  imports: [NgClass, NgTemplateOutlet, TabLabelDirective, ScrollableDirective],
  encapsulation: ViewEncapsulation.None,
})
export class TabGroupComponent implements AfterViewInit, OnDestroy {
  @Input()
  set selectedIndex(value: number | null) {
    this._selectedIndex = value || 0;
  }
  get selectedIndex(): number {
    return this._selectedIndex;
  }
  private _selectedIndex = 0;

  @Output()
  selectedIndexChange = new EventEmitter<number>();

  @HostBinding('class.sb-tab-group')
  readonly hostclass = true;

  @ViewChild(ScrollableDirective)
  scrollable!: ScrollableDirective;

  @ContentChildren(TabComponent, { descendants: true })
  tabs!: QueryList<TabComponent>;

  @ViewChildren(TabLabelDirective)
  labels!: QueryList<TabLabelDirective>;

  private keyManager?: FocusKeyManager<TabLabelDirective>;

  constructor(private readonly destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    this.setupKeyManager();
  }

  ngOnDestroy(): void {
    this.keyManager?.destroy();
  }

  selectTabByIndex(index: number, focusOrigin: FocusOrigin = 'mouse'): void {
    if (this.selectedIndex === index) return;

    this.keyManager?.setFocusOrigin(focusOrigin);
    this.keyManager?.setActiveItem(index);
    this.scrollable.scrollTo({ left: 0, top: 0 });
  }

  onKeyDown(event: KeyboardEvent): void {
    this.keyManager?.onKeydown(event);
  }

  private setupKeyManager(): void {
    this.keyManager = new FocusKeyManager(this.labels).withWrap().withHorizontalOrientation('ltr');
    this.keyManager.updateActiveItem(this.selectedIndex);

    this.keyManager.change.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((index) => {
      this.selectedIndex = index;
      this.selectedIndexChange.emit(index);
      this.tabs.get(index)?.selected.emit();
    });
  }
}
