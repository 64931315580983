export enum ButtonThemePalette {
  primary = 'primary',
  outlinePrimary = 'outlinePrimary',
  secondary = 'secondary',
  grey = 'grey',
  transparent = 'transparent',
  purple = 'purple',
  outlinePurple = 'outlinePurple',
  green = 'green',
  outlineGreen = 'outlineGreen',
  orange = 'orange',
  outlineOrange = 'outlineOrange',
  red = 'red',
  outlineRed = 'outlineRed',
}
export enum ButtonSize {
  sm = 'sm',
  base = 'base',
  lg = 'lg',
}

export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export type buttonThemePalette = keyof typeof ButtonThemePalette;
export type buttonSize = keyof typeof ButtonSize;
export type buttonType = keyof typeof ButtonType;
