import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Alignment, Fit, Layout, Rive } from '@rive-app/canvas';

@Directive({
  selector: 'canvas[sbRiveAnimation]',
  exportAs: 'sb-rive-animation',
  standalone: true,
})
export class RiveAnimationDirective implements OnChanges, OnDestroy {
  @Input({ required: true, alias: 'sbRiveAnimation' })
  public path!: string;

  private riveAnimation?: Rive;

  public constructor(private el: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['path'] && this.path) {
      this.riveAnimation = new Rive({
        src: this.path,
        canvas: this.el.nativeElement,
        autoplay: true,
        layout: new Layout({ fit: Fit.Contain, alignment: Alignment.Center }),
        onLoad: () => {
          this.riveAnimation?.resizeDrawingSurfaceToCanvas();
        },
      });
    }
  }

  public ngOnDestroy() {
    this.riveAnimation?.cleanup();
  }
}
