<div class="flex">
  <sb-icon [name]="icon" [size]="size === 'sm' ? 'base' : 'xl'"></sb-icon>
</div>
<div
  class="flex flex-col text-default children:my-0 children:text-size-inherit"
  [ngClass]="{
    'gap-3 ': size === 'md',
    'gap-2 ': size === 'sm'
  }"
>
  @if (title) {
    <h6 class="font-semibold" [innerHTML]="title"></h6>
  }
  <p class="font-normal text-support" [innerHTML]="description"></p>
  @if (link) {
    <a (click)="linkClicked.emit()" class="font-semibold">
      {{ link }}
    </a>
  }
</div>
