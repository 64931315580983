import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  ExistingProvider,
  forwardRef,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { CheckboxComponent } from '../checkbox';
import { DropdownModule, DropdownToggleDirective } from '../dropdown';
import { IconComponent } from '../icon';
import { InputModule } from '../input';
import { SearchInputComponent } from '../search-input';
import { TreeItem } from '../tree/tree.component';
import { TreeModule } from '../tree/tree.module';
import { SelectCollapseSpacerDirective } from './select-collapse-spacer.directive';

export type selectId = string | number;

export type selected = selectId | selectId[];

export interface SelectItem<T = selectId> extends TreeItem<T> {
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  children?: SelectItem<T>[];
}

export interface SelectFilter {
  label: string;
  type: 'selection' | 'items';
  callback: (checked: boolean, items: SelectItem[], selected: selected) => selected | SelectItem[];
}

export interface ItemFilterEvent {
  filter: SelectFilter;
  checked: boolean;
  items: SelectItem[];
  selected: selected;
}

const VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
};

@Component({
  selector: 'sb-select',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    IconComponent,
    FormsModule,
    TreeModule,
    TranslateModule,
    InputModule,
    CheckboxComponent,
    SelectCollapseSpacerDirective,
    SearchInputComponent,
  ],
  providers: [VALUE_ACCESSOR],
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements ControlValueAccessor {
  @Input()
  showPill = false;
  @Input()
  buttonActive = false;
  @Input()
  inline = false;
  @Input()
  customToggle = false; // only applicable when not inline
  @Input()
  hasError = false;
  @Input()
  collapsable = false;
  @Input()
  searchable = false;
  @Input()
  searchPlaceholder?: string;
  @Input()
  showSelectAll = false;
  @Input()
  selectText?: string;
  @Input()
  displayTree = false;
  @Input()
  nodePadding = true; // only relevant when displayed as a tree
  @Input()
  multiSelect = false;
  @Input()
  showOnly = false; // only supported if multiSelect is true
  @Input()
  matchWidth = true;
  @Input()
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this.cd.markForCheck();
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }
  private _disabled = false;

  @Input()
  set items(value: SelectItem[]) {
    this._items = value;
    this.displayedItems = this.getDisplayedItems();
    if (this.selected) {
      this.setSelectedText();
      if (this.multiSelect) {
        this.patchMultiSelectDisplayItems();
      }
    }
  }
  get items(): SelectItem[] {
    return this._items;
  }
  private _items: SelectItem[] = [];

  @ViewChild(DropdownToggleDirective, { static: false, read: ElementRef })
  dropdown?: ElementRef<HTMLButtonElement>;

  @ViewChild('menu', { static: true })
  menu?: TemplateRef<unknown>;

  @ContentChild('itemTemplate', { static: false })
  set itemTemplate(value: TemplateRef<any>) {
    if (!value) {
      return;
    }
    this.itemTemplateInput = value;
  }
  @Input()
  itemTemplateInput: TemplateRef<any> | undefined;

  @Input()
  filters: SelectFilter[] = [];

  query = '';
  displayedItems: SelectItem[] = [];

  @Input()
  set selected(value: selected) {
    this._selected = value;
    this.setSelectedText();
    if (this.multiSelect) {
      this.patchMultiSelectDisplayItems();
    }
    this.cd.markForCheck();
  }
  get selected(): selected {
    return this._selected;
  }
  private _selected: selected = '';

  selectedText?: string;

  @Input()
  selectPlaceholder?: string;

  @Output()
  public itemFilterChanged = new EventEmitter<ItemFilterEvent>();

  @HostBinding('class')
  public hostClasses = 'block';

  dropdownWidth?: string;

  allChecked = false;

  constructor(
    protected cd: ChangeDetectorRef,
    protected translate: TranslateService,
  ) {}

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => any = () => {};

  // Implemented as part of ControlValueAccessor.
  writeValue(value: selected): void {
    this.selected = value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get selectedCount() {
    if (Array.isArray(this.selected)) {
      return this.selected.length;
    }
    return this.selected ? 1 : 0;
  }

  get selectButtonLabel(): string {
    if (this.selectText) {
      return this.selectText;
    }
    if (this.multiSelect && this.selectedCount > 1) {
      return this.translate.instant('{{count}} selected', { count: this.selectedCount });
    }
    if (this.selectedText) {
      return this.selectedText;
    }
    return this.selectPlaceholder || '';
  }

  onOpened(): void {
    this.dropdownWidth = `${this.dropdown?.nativeElement.offsetWidth}px`;
  }

  onSearch(): void {
    if (!this.searchable) {
      return;
    }

    this.displayedItems = this.getDisplayedItems();

    if (this.multiSelect) {
      this.patchMultiSelectDisplayItems();
      return;
    }
  }

  getDisplayedItems(): SelectItem[] {
    return this.query ? this.filterItems(this.items) : this.items;
  }

  onItemTrigger(item: SelectItem): void {
    this.selectedText = item.text;
    this.onSelectChange(item.value);
  }

  onToggleCollapse(item: SelectItem): void {
    if (!this.collapsable) {
      return;
    }
    item.collapsed = !item.collapsed;
  }

  onCheckboxChange(checked: boolean, selectedItem: SelectItem): void {
    // ensure select is an array
    if (!Array.isArray(this.selected)) {
      this.selected = [];
    }
    // check which items were changed
    const changed =
      this.displayTree && selectedItem.children ? this.getChildIds(selectedItem.children) : [selectedItem.value];

    // update selected
    if (checked) {
      this.onSelectChange([...new Set([...this.selected, ...changed])]);
    } else {
      this.onSelectChange(this.selected.filter((selected: selectId) => !changed.includes(selected)));
    }

    this.patchMultiSelectDisplayItems();
  }

  onSelectAll(allSelected: boolean) {
    if (allSelected) {
      const selected = this.displayTree
        ? this.getChildIds(this.displayedItems)
        : this.displayedItems.map((item) => item.value);
      this.onSelectChange(selected);
    } else {
      this.onSelectChange([]);
    }
    this.patchMultiSelectDisplayItems();
  }

  onFilterChange(checked: boolean, filter: SelectFilter) {
    if (filter.type === 'items') {
      this.itemFilterChanged.emit({ filter, checked, items: this.items, selected: this.selected });
      return;
    }

    const selected = filter.callback(checked, this.items, this.selected);
    this.onSelectChange(selected as selected);
    if (this.multiSelect) {
      this.patchMultiSelectDisplayItems();
    }
  }

  trackByFn(_: number, item: SelectItem): selectId {
    return item.value;
  }

  onSelectOnly(item: SelectItem) {
    const selected = [item.value, ...(item.children ? this.getChildIds(item.children) : [])];
    this.onSelectChange(selected);
  }

  private onSelectChange(selected: selected) {
    this.selected = selected;

    this.onChange(selected);
    this.onTouched();
  }

  private patchMultiSelectDisplayItems() {
    this.displayedItems = this.displayedItems.map((item) => this.patchMultiSelectItem(item));
    this.allChecked = this.getAllChecked();
  }

  private getAllChecked(): boolean {
    if (!this.displayedItems.length) {
      return false;
    }
    return this.displayedItems.every((item) =>
      this.displayTree ? this.childrenAllChecked(item) : this.itemIsSelected(item),
    );
  }

  private patchMultiSelectItem(item: SelectItem): SelectItem {
    if (this.displayTree && item.children) {
      const checked = this.childrenAllChecked(item);
      const indeterminate = checked ? false : this.childrenSomeChecked(item);
      return {
        ...item,
        checked,
        indeterminate,
        children: item.children.map((child) => this.patchMultiSelectItem(child)),
      };
    }
    const checked = this.itemIsSelected(item);
    return { ...item, checked, indeterminate: false };
  }

  private childrenAllChecked(item: SelectItem): boolean {
    return (item.children || []).every((child) =>
      child.children ? this.childrenAllChecked(child) : this.itemIsSelected(child),
    );
  }

  private childrenSomeChecked(item: SelectItem): boolean {
    return (item.children || []).some((child) =>
      child.children ? this.childrenSomeChecked(child) : this.itemIsSelected(child),
    );
  }

  private itemIsSelected(item: SelectItem): boolean {
    return Array.isArray(this.selected) ? this.selected.includes(item.value) : this.selected === item.value;
  }

  private getChildIds(items: SelectItem[]): selectId[] {
    return items.reduce((acc: selectId[], item) => {
      if (item.children) {
        return acc.concat(this.getChildIds(item.children));
      }
      if (!item.disabled) {
        acc.push(item.value);
      }
      return acc;
    }, []);
  }

  private setSelectedText(): void {
    if (Array.isArray(this.selected)) {
      this.selectedText = this.selectedCount > 1 ? '' : this.getSelectedTextFromItem(this.selected[0]);
    } else {
      this.selectedText = this.selected ? this.getSelectedTextFromItem(this.selected as string | number) : undefined;
    }
  }

  private getSelectedTextFromItem(selected: string | number): string | undefined {
    return this.findItem(this.items, selected)?.text;
  }

  private findItem(items: SelectItem[], value: string | number): SelectItem | undefined {
    for (const item of items) {
      if (item.value === value) {
        return item;
      }
      if (item.children) {
        const child = this.findItem(item.children, value);
        if (child) {
          return child;
        }
      }
    }
    return undefined;
  }

  private filterItems(items: SelectItem[]): SelectItem[] {
    return items.reduce((acc, item) => {
      const children = this.filterItems(item?.children || []);
      if (children.length > 0) {
        return [...acc, { ...item, children, collapsed: false }];
      }
      if (this.hasMatch(item)) {
        return [...acc, item];
      }
      return acc;
    }, [] as SelectItem[]);
  }

  private hasMatch(item: SelectItem): boolean {
    return item.text.toLowerCase().includes(this.query.toLowerCase());
  }
}
