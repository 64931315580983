import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { sbIcons } from '@sb/svg-icons';

import { AvatarComponent } from '../avatar/avatar.component';
import { BadgeComponent } from '../badge/badge.component';
import { ButtonComponent } from '../button';
import { IconComponent } from '../icon';
import { PopoverModule } from '../popover';

interface Item {
  icon: sbIcons;
  label: string;
}

@Component({
  selector: 'sb-employee-popover',
  standalone: true,
  imports: [PopoverModule, TranslateModule, ButtonComponent, BadgeComponent, IconComponent, AvatarComponent],
  templateUrl: './employee-popover.component.html',
})
export class EmployeePopoverComponent {
  @Input()
  public id?: string;
  @Input()
  public firstName?: string;
  @Input()
  public lastName?: string;
  @Input()
  public location?: string;
  @Input()
  public department?: string;
  @Input()
  public team?: string;
  @Input()
  public jobTitle?: string;
  @Input()
  public email?: string;
  @Input()
  public phone?: string;
  @Input()
  public contractEndDate?: string;
  @Input()
  public avatarUrl?: string;
  @Input()
  public showProfileButton?: boolean;

  @Output()
  public viewProfile = new EventEmitter();

  public constructor(private translate: TranslateService) {}

  public get items(): Item[] {
    const items: Item[] = [];
    if (this.jobTitle) {
      items.push({ icon: 'suitcase', label: this.jobTitle });
    }
    if (this.email) {
      items.push({ icon: 'email', label: this.email });
    }
    if (this.phone) {
      items.push({ icon: 'phone', label: this.phone });
    }
    if (this.contractEndDate) {
      items.push({
        icon: 'calendar-month',
        label: `${this.translate.instant('Contract ends on')} ${this.contractEndDate}`,
      });
    }
    return items;
  }
}
