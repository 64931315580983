import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { clsx } from 'clsx';

import { ButtonSize, buttonSize, ButtonThemePalette, buttonThemePalette } from './button.model';

export const BUTTON_HOST = {
  '[class.pointer-events-none]': '!clickable',
  '[attr.disabled]': 'disabled ? true : null',
  '[attr.aria-disabled]': 'disabled ? true : null',
};

const BUTTON_CLASSES_BASE = clsx(
  'relative inline-flex cursor-pointer select-none items-center justify-center space-x-3 overflow-visible rounded-base border border-solid text-center font-semibold outline-none children-icon:flex-none',
  'focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
  'disabled:pointer-events-none aria-disabled:pointer-events-none',
);

const BUTTON_CLASSES_FONT_SIZE: {
  [size: string]: string;
} = {
  [ButtonSize.sm]: clsx('text-sm'),
  [ButtonSize.base]: clsx('text-base'),
  [ButtonSize.lg]: clsx('text-lg'),
};

const BUTTON_CLASSES_SPACE: {
  [size: string]: string;
} = {
  [ButtonSize.sm]: clsx('px-2 py-1'),
  [ButtonSize.base]: clsx('px-3 py-1.5'),
  [ButtonSize.lg]: clsx('px-12 py-4'),
};

const ICON_BUTTON_CLASSES_SPACE: {
  [size: string]: string;
} = {
  [ButtonSize.sm]: clsx('p-1.5'),
  [ButtonSize.base]: clsx('p-1.5'),
  [ButtonSize.lg]: clsx('p-4'),
};

const BUTTON_CLASSES_COLOR: {
  [palette: string]: string;
} = {
  [ButtonThemePalette.primary]: clsx(
    'border-primary-500 bg-primary-500 text-white',
    'hover:border-primary-600 hover:bg-primary-600',
    'focus-visible:outline-primary-500/50 active:border-primary-600 active:bg-primary-600',
    'disabled:border-primary-200 disabled:bg-primary-200',
    'aria-disabled:border-primary-200 aria-disabled:bg-primary-200',
  ),
  [ButtonThemePalette.outlinePrimary]: clsx(
    'border-primary-500 bg-white text-primary-500',
    'hover:bg-primary-50',
    'focus-visible:outline-primary-500/50 active:bg-primary-50',
    'disabled:border-primary-500/50 disabled:text-primary-500/50',
    'aria-disabled:border-primary-500/50 aria-disabled:text-primary-500/50',
  ),
  [ButtonThemePalette.secondary]: clsx(
    'border-grey-300 bg-white text-grey-800 hover:border-grey-300',
    'hover:bg-grey-50',
    'focus-visible:outline-grey-500/25 active:border-grey-300 active:bg-grey-50',
    'disabled:border-grey-200 disabled:text-grey-400',
    'aria-disabled:border-grey-200 aria-disabled:text-grey-400',
  ),
  [ButtonThemePalette.red]: clsx(
    'border-red-500 bg-red-500 text-white',
    'hover:border-red-600 hover:bg-red-600',
    'focus-visible:outline-red-500/50 active:border-red-600 active:bg-red-600',
    'disabled:border-red-300 disabled:bg-red-300',
    'aria-disabled:border-red-300 aria-disabled:bg-red-300',
  ),
  [ButtonThemePalette.outlineRed]: clsx(
    'border-red-500 bg-white text-red-500',
    'hover:bg-red-50',
    'focus-visible:outline-red-500/50 active:bg-red-50',
    'disabled:border-red-500/50 disabled:text-red-500/50',
    'aria-disabled:border-red-500/50 aria-disabled:text-red-500/50',
  ),
  [ButtonThemePalette.orange]: clsx(
    'border-orange-500 bg-orange-500 text-white',
    'hover:border-orange-600 hover:bg-orange-600',
    'focus-visible:outline-orange-500/50 active:border-orange-700 active:bg-orange-700',
    'disabled:border-orange-200 disabled:bg-orange-200',
    'aria-disabled:border-orange-200 aria-disabled:bg-orange-200',
  ),
  [ButtonThemePalette.outlineOrange]: clsx(
    'border-orange-500 bg-white text-orange-500',
    'hover:bg-orange-50',
    'focus-visible:outline-orange-500/50 active:bg-orange-50',
    'disabled:border-orange-500/50 disabled:text-orange-500/50',
    'aria-disabled:border-orange-500/50 aria-disabled:text-orange-500/50',
  ),
  [ButtonThemePalette.purple]: clsx(
    'border-purple-100 bg-purple-100 text-purple-500',
    'hover:border-purple-200 hover:bg-purple-200',
    'focus-visible:outline-purple-500/50 active:border-purple-200 active:bg-purple-200',
    'disabled:border-purple-50 disabled:bg-purple-50 disabled:text-purple-300',
    'aria-disabled:border-purple-50 aria-disabled:bg-purple-50 aria-disabled:text-purple-300',
  ),
  [ButtonThemePalette.outlinePurple]: clsx(
    'border-purple-500 bg-white text-purple-500',
    'hover:bg-purple-50',
    'focus-visible:outline-purple-500/50 active:bg-purple-50',
    'disabled:border-purple-500/50 disabled:text-purple-500/50',
    'aria-disabled:border-purple-500/50 aria-disabled:text-purple-500/50',
  ),
  [ButtonThemePalette.green]: clsx(
    'border-green-500 bg-green-500 text-white',
    'hover:border-green-600 hover:bg-green-600',
    'focus-visible:outline-green-500/50 active:border-green-700 active:bg-green-700',
    'disabled:border-green-200 disabled:bg-green-200',
    'aria-disabled:border-green-200 aria-disabled:bg-green-200',
  ),
  [ButtonThemePalette.outlineGreen]: clsx(
    'border-green-500 bg-white text-green-500',
    'hover:bg-green-50',
    'focus-visible:outline-green-500/50 active:bg-green-50',
    'disabled:border-green-500/50 disabled:text-green-500/50',
    'aria-disabled:border-green-500/50 aria-disabled:text-green-500/50',
  ),
  [ButtonThemePalette.transparent]: clsx(
    'border-transparent bg-transparent text-grey-800',
    'hover:bg-grey-300/25',
    'focus-visible:outline-grey-300/25',
    'disabled:text-grey-400',
    'aria-disabled:text-grey-400',
  ),
  [ButtonThemePalette.grey]: clsx(
    'border-grey-200 bg-grey-200 text-grey-500',
    'hover:border-grey-300 hover:bg-grey-300 ',
    'focus-visible:outline-grey-300 active:border-grey-300 active:bg-grey-300 ',
    'disabled:border-grey-200 disabled:bg-grey-200',
    'aria-disabled:border-grey-200 aria-disabled:bg-grey-200',
  ),
};

@Directive()
export class ButtonBaseDirective {
  @Input()
  set clickable(value: boolean) {
    if (value === this._clickable) {
      return;
    }
    this._clickable = value;
    this.tabIndex = this.clickable ? undefined : -1;
    if (!this.clickable && this.hasFocus) {
      this.blur();
    }
  }
  get clickable() {
    return this._clickable;
  }
  private _clickable = true;

  @HostBinding('attr.tabindex')
  tabIndex: number | undefined = undefined;

  @HostBinding('class')
  hostclasses: string;

  @Input()
  set color(value: buttonThemePalette) {
    if (value === this._color) {
      return;
    }
    this._color = value;
    this.hostclasses = this.getHostClasses();
  }
  get color() {
    return this._color;
  }
  private _color: buttonThemePalette = ButtonThemePalette.secondary;

  @Input()
  disabled = false;

  @Input()
  set size(value: buttonSize) {
    if (value === this._size) {
      return;
    }
    this._size = value;
    this.hostclasses = this.getHostClasses();
  }
  get size() {
    return this._size;
  }
  private _size: buttonSize = ButtonSize.base;

  @Input()
  @HostBinding('class.w-full')
  block = false;

  constructor(private elementRef: ElementRef<HTMLButtonElement | HTMLAnchorElement>) {
    elementRef.nativeElement.classList.add(...BUTTON_CLASSES_BASE.split(' '));
    this.hostclasses = this.getHostClasses();
  }

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  blur(): void {
    this.elementRef.nativeElement.blur();
  }

  private getHostClasses(): string {
    const spacing = this.elementRef.nativeElement.hasAttribute('sb-icon-button')
      ? ICON_BUTTON_CLASSES_SPACE
      : BUTTON_CLASSES_SPACE;
    return clsx(spacing[this.size], BUTTON_CLASSES_FONT_SIZE[this.size], BUTTON_CLASSES_COLOR[this.color]);
  }

  private get hasFocus() {
    return this.elementRef.nativeElement === document.activeElement;
  }
}
