import { supportsScrollBehavior } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  inject,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { auditTime, fromEvent } from 'rxjs';

@Directive({
  selector: '[sbScrollable]',
  exportAs: 'sbScrollable',
  standalone: true,
})
export class ScrollableDirective implements AfterViewInit, OnDestroy {
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  private readonly destroyRef = inject(DestroyRef);
  private readonly ngZone = inject(NgZone);
  private readonly cdr = inject(ChangeDetectorRef);

  @HostBinding('class.sb-scrollable')
  readonly scrollableClass = true;
  @HostBinding('class.sb-scrollable-top')
  hasScrollTop = false;
  @HostBinding('class.sb-scrollable-bottom')
  hasScrollBottom = false;

  protected resizeObserver = globalThis?.ResizeObserver
    ? new globalThis.ResizeObserver(() => {
        this.ngZone.run(() => {
          this.evaluateScrollPosition();
        });
      })
    : null;

  ngAfterViewInit() {
    this.evaluateScrollPosition(true);

    const element: HTMLElement = this.elementRef.nativeElement;
    fromEvent(element, 'scroll')
      .pipe(takeUntilDestroyed(this.destroyRef), auditTime(100))
      .subscribe(() => {
        this.ngZone.run(() => {
          this.evaluateScrollPosition();
        });
      });

    this.resizeObserver?.observe(element);
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
    this.resizeObserver = null;
  }

  scrollTo(options: ScrollToOptions): void {
    const el: HTMLElement = this.elementRef.nativeElement;

    if (supportsScrollBehavior()) {
      el.scrollTo(options);
    } else {
      if (options.top != null) {
        el.scrollTop = options.top;
      }
      if (options.left != null) {
        el.scrollLeft = options.left;
      }
    }
  }

  private evaluateScrollPosition(detectChanges = false): void {
    const el: HTMLElement = this.elementRef.nativeElement;
    const hasScrollbar = el.scrollHeight > el.clientHeight;
    const reach = hasScrollbar ? this.scrollReach() : undefined;

    this.hasScrollTop = hasScrollbar && reach !== 'top';
    this.hasScrollBottom = hasScrollbar && reach !== 'bottom';

    // detech changes on the first pass to avoid ExpressionChangedAfterItHasBeenCheckedError
    if (detectChanges && hasScrollbar) {
      this.cdr.detectChanges();
    }
  }

  private scrollReach(): 'top' | 'bottom' | undefined {
    const el: HTMLElement = this.elementRef.nativeElement;
    if (el.scrollTop < 1) {
      return 'top';
    }
    // minus 1 for sub pixel rounding
    if (el.scrollTop > el.scrollHeight - el.clientHeight - 1) {
      return 'bottom';
    }

    return undefined;
  }
}
