import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable, TemplateRef } from '@angular/core';

import { DialogAnnouncementComponent, DialogAnnouncementData, DialogAnnouncementResult } from './announcement';
import { DialogConfirmComponent, DialogConfirmData, DialogConfirmResult } from './confirm';
import { dialogSize, DialogWidth, overlayPosition } from './dialog.models';
import { getDialogWidth, SbDialogContainerComponent } from './internal';

@Injectable()
export class SbDialogService {
  public nextDialogConfig!: DialogConfig<any, any>;
  private readonly widths = DialogWidth;

  private baseConfig = {
    hasBackdrop: true,
    autoFocus: false,
    container: SbDialogContainerComponent,
    maxHeight: '90vh',
    maxWidth: '90vw',
    width: this.widths.md,
  };

  constructor(
    private readonly dialog: Dialog,
    private readonly overlay: Overlay,
  ) {
    this.resetDialogConfig();
  }

  get openedDialogs() {
    return this.dialog.openDialogs;
  }

  open<T = unknown, D = unknown, R = unknown>(
    component: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig<D, DialogRef<R, T>>,
  ) {
    const dialogConfig: DialogConfig<D, DialogRef<R, T>> = {
      ...this.nextDialogConfig,
      ...config,
    };

    const dialogRef = this.dialog.open(component, dialogConfig);

    this.resetDialogConfig();

    return dialogRef;
  }

  // Helper methods

  closeAll(): void {
    this.dialog.closeAll();
  }

  getClosestDialog(element: ElementRef<HTMLElement>) {
    //  Find the closest DialogRef to an element by looking at the DOM
    let parent: HTMLElement | null = element.nativeElement.parentElement;

    while (parent && !parent.classList.contains('sb-dialog-container')) {
      parent = parent.parentElement;
    }

    const parentId = parent?.id;
    return parentId ? this.openedDialogs.find((dialog) => dialog.id === parentId) : undefined;
  }

  setSize(size: dialogSize) {
    this.nextDialogConfig.width = getDialogWidth(size);

    return this;
  }

  setPosition(position: overlayPosition) {
    switch (position) {
      case 'top':
        this.nextDialogConfig.positionStrategy = this.overlay.position().global().centerHorizontally().top('32px');
        break;
      // center
      default:
        this.nextDialogConfig.positionStrategy = this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically();
    }

    return this;
  }
  // Utility dialogs

  openAnnouncement(
    data: DialogAnnouncementData,
    disableClose = false,
  ): DialogRef<DialogAnnouncementResult, DialogAnnouncementComponent> {
    return this.setSize('sm').open(DialogAnnouncementComponent, { data, disableClose });
  }

  openConfirm(data: DialogConfirmData): DialogRef<DialogConfirmResult, DialogConfirmComponent> {
    return this.setSize('sm').open(DialogConfirmComponent, { data });
  }

  private resetDialogConfig() {
    this.nextDialogConfig = {
      ...this.baseConfig,
    };
  }
}
